import React from "react";
import {Helmet} from "react-helmet/es/Helmet";
import PageWrapper from "../components/PageWrapper";
import {Title} from "../components/Core";
import Section from "../components/Section";
import {Col, Row, Container} from "react-bootstrap";
import "../assets/map/LayeredUKMap-v2.scss"
import styled from "styled-components";
import {ReactSVG} from 'react-svg'
import UKMapNetworkV1 from "../assets/image/svg/uk-map-network-complete.svg"
import Seo from "./../components/Seo";

const FormStyled = styled.div`
  padding: 10px 30px 80px 30px;
  border-radius: 10px;
  background-color: ${({theme}) => theme.colors.light};
`;

const OurNetwork = () => {

    return (
        <>
            <Seo page="network"/>
            <Helmet>
                <title>Netomnia | Our Network</title>
                <meta name="description" content="Netomnia | Our Network"/>
            </Helmet>
            <PageWrapper>
                <Section className="text-center">
                    <Container>
                        <Row>
                            <Col sm={12} className="text-center pt-5 mt-3 pb-1">
                                <Title>Our Network</Title>
                            </Col>
                        </Row>
                        <FormStyled>
                            <Row className="mt-5 pt-4 text-center">
                                {/* SVG Map layers */}
                                <Col sm={12} className="mapContainer">
                                    <ReactSVG
                                        src={UKMapNetworkV1}
                                        key="UKMap"
                                        className="mapLayer"
                                    />
                                </Col>

                            </Row>
                        </FormStyled>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default OurNetwork;
